












import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { CreateCommunityViewModel } from '../../viewmodels/create-community-viewmodel'

@Observer
@Component({
  components: {},
})
export default class ContributorAppendInput extends Vue {
  @Inject({}) vm!: CreateCommunityViewModel
  @Prop({}) contributor!: any
}
